import React from 'react';
import { useIntl } from 'react-intl';
import { BankIdLogInButton } from '@aph/components/common';
import { ContentRenderer } from '~/contentful/components/content-renderer/content-renderer';
import type { IPageClubHjartatFields } from '~/contentful/types';

type RedirectToLoginUrlTypeProps = {
  contentfulData?: IPageClubHjartatFields;
};

export const RegisterMemberLogin: React.FC<RedirectToLoginUrlTypeProps> = ({
  contentfulData,
  ...props
}) => {
  const intl = useIntl();

  return (
    <div className="flex flex-col" {...props}>
      <ContentRenderer content={contentfulData?.pageContent} />

      <BankIdLogInButton
        className="self-center"
        data-pw="register-member-login-button"
        data-testid="REGISTERMEMBERLOGIN.LOGIN.BUTTON"
        redirectLoginUrl="create_member"
        variant="secondary"
        titleId="CLUB_MEMBER.REGISTER.BUTTON.TEXT"
        aria-label={intl
          .formatMessage({ id: 'CLUB_MEMBER.REGISTER.BUTTON.TEXT_ARIA_LABEL' })
          .toString()}
      />
    </div>
  );
};
