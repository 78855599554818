import type { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next';
import { useClubMemberNavItems } from '@aph/components/club-member/nav-items/use-club-member-nav-items';
import { DesktopMenu } from '@aph/components/club-member/page-layout/desktop-menu.component';
import { RegisterWizardContainer } from '@aph/components/club-member/register/register-wizard/register-wizard.container';
import { Breadcrumbs } from '@aph/components/layouts/breadcrumb.component';
import { MobileMenu } from '@aph/components/layouts/page-components/mobile-menu.component';
import { PageLayout } from '@aph/components/layouts/page-layout.component';
import { useGetSEOPropsWithFallbacks } from '@aph/hooks/use-get-seo-props-with-fallbacks/use-get-seo-props-with-fallbacks';
import { Typography } from '@aph/ui/components/typography/typography';
import { isErrorWithStatus } from '@aph/utilities/errors/is-error-with-status';
import { withPageErrorHandler } from '@aph/utilities/with-page-error-handler';
import { ContentApiClient } from '~/contentful/api/ContentApiClient';
import { InfoBar } from '~/contentful/components/info-bar/info-bar';
import type { IInfoBar, IPageClubHjartat, IPageClubHjartatFields } from '~/contentful/types';
import { mapContentfulSEOToNextSEO } from '~/contentful/utilities/next-seo-from-contentful';
import { authRedirectConfig } from '~/contexts/auth/auth.redirect.config';
import { logger } from '~/logging';

const RegisterMember: NextPage<InferGetServerSidePropsType<typeof getServerSideProps>> = ({
  contentfulData,
  infoBar,
}) => {
  const { primaryNavItems, secondaryNavItems, allNavItems, isAuthenticated } =
    useClubMemberNavItems();
  const isLoading = isAuthenticated === undefined;

  const seoProps = useGetSEOPropsWithFallbacks({
    pageTitle: contentfulData.fields.pageTitle,
    seoProps: mapContentfulSEOToNextSEO(contentfulData.fields.seo?.fields),
    customFallbacks: {
      title: 'Registrera medlemskap',
      description: 'Registrera nytt klubbmedlemskap',
    },
  });

  const { pageTitle } = contentfulData.fields;

  return (
    <PageLayout
      seo={seoProps}
      top={infoBar ? <InfoBar data={infoBar.fields} /> : undefined}
      hero={
        <div className="flex flex-col gap-2 md:max-w-2xl">
          <Breadcrumbs menuItems={allNavItems} />
          <div className="md:hidden">
            <MobileMenu menuItems={allNavItems} />
          </div>
          <Typography typography="headingExtraLarge" color="text-brand" asChild>
            <h1>{pageTitle}</h1>
          </Typography>
        </div>
      }
      sidebar={
        <DesktopMenu
          primaryNavItems={primaryNavItems}
          secondaryNavItems={secondaryNavItems}
          isLoading={isLoading}
        />
      }
    >
      <RegisterWizardContainer contentfulData={contentfulData.fields} />
    </PageLayout>
  );
};

export type RegisterMemberProps = {
  contentfulData: IPageClubHjartatFields;
};

export const getServerSideProps = (async ({ res, req }) => {
  const { hasNoSessionRedirect } = authRedirectConfig();

  const pageSlug = 'klubb-hjartat/bli-medlem';
  const infoBarSlug = 'klubb-hjartat-info-bar';

  try {
    const contentApiClient = new ContentApiClient({ headers: req.headers });

    const [contentfulData, infoBar] = await Promise.all([
      contentApiClient.getEntityBySlug<IPageClubHjartat>({
        slug: pageSlug,
        contentType: 'pageClubHjartat',
      }),
      contentApiClient
        .getEntityBySlug<IInfoBar>({
          slug: infoBarSlug,
          contentType: 'infoBar',
        })
        .catch(() => null),
    ]);

    return {
      props: {
        contentfulData,
        infoBar,
        hasNoSessionReturnPath: hasNoSessionRedirect,
      },
    };
  } catch (error: unknown) {
    logger.error(error, `Error in getServerSideProps with slug "${pageSlug}"`);
    if (isErrorWithStatus(error)) {
      if (error.status >= 500) {
        res.statusCode = 503;
        return {
          props: {
            error: { status: 503 },
            contentfulData: null as unknown as IPageClubHjartat,
            infoBar: null,
          },
        };
      }
    }

    return {
      notFound: true,
    };
  }
}) satisfies GetServerSideProps<{
  contentfulData: IPageClubHjartat;
  infoBar: IInfoBar | null;
}>;

export default withPageErrorHandler(RegisterMember);
