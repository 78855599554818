
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/klubb-hjartat/bli-medlem",
      function () {
        return require("private-next-pages/klubb-hjartat/bli-medlem/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/klubb-hjartat/bli-medlem"])
      });
    }
  