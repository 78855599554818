import { colorTokens } from '@aph/ui/design-tokens/color-tokens';

export default {
  color: colorTokens['color/text/action-dark'],
  textDecoration: 'underline',
  '&:hover': {
    textDecoration: 'none',
    cursor: 'pointer',
  },
};
