/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@aph/ui/components/typography/typography';
import { localizedErrorMessage } from '@aph/utilities/errors/errorLocalization';
import type { ErrorCode, ErrorIntlKey, ErrorMessage } from '@aph/utilities/errors/types';
import { SubmitButtonWithStates } from '~/components/common';
import { useGtm } from '~/components/gtm/useGtm';
import type { UserInfo } from '~/services/generated/DispatcherClient';
import { PharmacySelect } from '../..';
import { CustomerPanelContainer } from '../../customer-panel/customer-panel.container';
import { MemberDataInputContainer } from '../../member-data-input/member-data-input.container';
import type { CreateNewMemberFormValues } from '../../member-info.schema';
import { MemberInfoNames, newMemberSchema } from '../../member-info.schema';
import { OffersContainer } from '../../offers/offers.container';
import { classes } from './register-member.styles';

type RegisterMemberProps = {
  userInfo: UserInfo | null;
  onSuccessfulSave: () => void;
  errorMember: ErrorIntlKey | (ErrorMessage & ErrorCode) | null;
  submittingMember: boolean | null;
  successMember: boolean | null;
  onSave: (memberInfo: CreateNewMemberFormValues) => void;
};

export const RegisterMemberComponent: React.FC<RegisterMemberProps> = ({
  userInfo,
  onSuccessfulSave,
  errorMember,
  submittingMember,
  successMember,
  onSave,
  ...props
}) => {
  const intl = useIntl();
  const formStateMember = useForm<CreateNewMemberFormValues>({
    resolver: yupResolver(newMemberSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });
  const { sendEvent } = useGtm();

  const onSubmitMember: SubmitHandler<CreateNewMemberFormValues> = (memberInfo) => {
    onSave(memberInfo);
  };

  const errorMessageServer = (errorX: ErrorIntlKey | (ErrorMessage & ErrorCode) | null) => {
    if (errorX) {
      const err = localizedErrorMessage(errorX, intl);
      if (err != null) {
        const errorMessage = { message: err };
        return errorMessage;
      }
    }
    return null;
  };

  const { ref: firstNameRef, ...firstNameProps } = formStateMember.register(
    MemberInfoNames.FIRST_NAME,
  );
  const { ref: lastNameRef, ...lastNameProps } = formStateMember.register(
    MemberInfoNames.LAST_NAME,
  );
  const { ref: generalApprovalRef, ...generalApprovalProps } = formStateMember.register(
    MemberInfoNames.CONSENT_GENERAL_APPROVAL,
  );

  return (
    <div className="mt-3 flex flex-col flex-nowrap gap-5 sm:mt-2 sm:gap-6">
      <Typography typography="headingLarge" color="text-brand" asChild>
        <h1>
          <FormattedMessage id="CLUB_MEMBER.REGISTER.TITLE" />
        </h1>
      </Typography>
      <div {...props}>
        {userInfo && (
          <FormProvider {...formStateMember}>
            <form onSubmit={formStateMember.handleSubmit(onSubmitMember)}>
              <FormControl margin="normal">
                {/* FirstName input */}
                <div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
                  <TextField
                    sx={classes.textField}
                    label={intl.formatMessage({
                      id: 'COMMON.FORM_ELEMENTS.INPUT_FIRST_NAME.LABEL',
                    })}
                    {...firstNameProps}
                    id="COMMON.FORM_ELEMENTS.INPUT_FIRST_NAME.LABEL"
                    type="text"
                    variant="filled"
                    color="secondary"
                    size="small"
                    error={!!formStateMember.formState.errors.firstName}
                    defaultValue={userInfo.firstName}
                    inputRef={firstNameRef}
                    helperText={
                      formStateMember.formState.errors.firstName
                        ? intl.formatMessage({
                            id: formStateMember.formState.errors.firstName.message!,
                          })
                        : null
                    }
                    inputProps={{
                      spellCheck: 'false',
                      form: {
                        autocomplete: 'off',
                      },
                      'data-testid': 'REGISTER.FIRST.NAME',
                    }}
                  />
                  {/* LastName input */}

                  <TextField
                    sx={classes.textField}
                    label={intl.formatMessage({
                      id: 'COMMON.FORM_ELEMENTS.INPUT_LAST_NAME.LABEL',
                    })}
                    {...lastNameProps}
                    id="COMMON.FORM_ELEMENTS.INPUT_LAST_NAME.LABEL"
                    type="text"
                    variant="filled"
                    color="secondary"
                    size="small"
                    error={!!formStateMember.formState.errors.lastName}
                    defaultValue={userInfo.lastName}
                    inputRef={lastNameRef}
                    helperText={
                      formStateMember.formState.errors.lastName
                        ? intl.formatMessage({
                            id: formStateMember.formState.errors.lastName.message!,
                          })
                        : null
                    }
                    inputProps={{
                      spellCheck: 'false',
                      form: {
                        autocomplete: 'off',
                      },
                      'data-testid': 'REGISTER.LAST.NAME',
                    }}
                  />
                </div>
                <MemberDataInputContainer memberError={errorMember} />
                <OffersContainer wantsEmail wantsMail wantsSms />
                <PharmacySelect selectedIcaId={undefined} />
                <CustomerPanelContainer isSelected={false} />
                {/* membership terms approval */}
                <FormGroup sx={{ ...classes.checkboxWrapper }}>
                  <FormControlLabel
                    label={
                      <Typography
                        typography="body"
                        dangerouslySetInnerHTML={{
                          __html: intl.formatMessage({
                            id: 'CLUB_MEMBER.CONSENT.GENERAL_APPROVAL',
                          }),
                        }}
                      />
                    }
                    sx={classes.text}
                    control={
                      <Checkbox
                        {...generalApprovalProps}
                        inputRef={generalApprovalRef}
                        color="primary"
                        data-testid="CLUB.MEMBER.CONSENT.GENERAL.APPROVAL"
                      />
                    }
                  />
                  {formStateMember.formState.errors.consentGeneralApproval && (
                    <FormHelperText>
                      <FormattedMessage
                        id={formStateMember.formState.errors.consentGeneralApproval.message!}
                      />
                    </FormHelperText>
                  )}
                </FormGroup>
              </FormControl>
              <SubmitButtonWithStates
                className="sm:items-end"
                buttonText={intl.formatMessage({ id: 'CLUB_MEMBER.REGISTER.MEMBER.SAVE' })}
                successButtonText={intl.formatMessage({ id: 'CLUB_MEMBER.MEMBER.SAVED' })}
                submitting={submittingMember}
                success={successMember}
                error={errorMessageServer(errorMember)}
                afterSuccessAction={() => {
                  onSuccessfulSave();
                  // event is send to GTM when a member is succellfull created
                  sendEvent({ event: 'sign_up' });
                }}
                afterSuccessActionDelay={0}
              />
            </form>
          </FormProvider>
        )}
      </div>
    </div>
  );
};
