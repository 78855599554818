import React, { useEffect, useState } from 'react';
import { useAuthState } from '~/contexts/auth/auth.provider';
import { useFeatureToggle } from '~/model/feature-toggle/useFeatureToggle';
import {
  useAmIMemberActions,
  useAmIMemberStore,
} from '~/model/member/am-i-member/use-am-i-member-store';
import { useMemberActions, useMemberStore } from '~/model/member/use-member-store';
import type { RegisterMemberProps } from '~/pages/klubb-hjartat/bli-medlem';
import type { CreateNewMemberFormValues } from '../../member-info.schema';
import { RegisterWizard } from './register-wizard.component';

export const RegisterWizardContainer: React.FC<RegisterMemberProps> = ({ contentfulData }) => {
  const { userInfo } = useAuthState();
  const { member } = useMemberStore();
  const { createMemberData } = useMemberActions();
  const { amIMember } = useAmIMemberStore();
  const { getAmIMember } = useAmIMemberActions();
  const { submitting, success, error } = member;
  const [activeStep, setActiveStep] = useState(0);

  // Created enable_register toggle insted of disable_register because the way features toggel works when using the GUI to toggle on apotekhjartat.se/feature-toggles. The toggle varibel needs to be set to disable for the inviorment for the GUI to toggel.
  const { isEnabled: isRegisterClubMemberEnabled } = useFeatureToggle(
    'aph_features_enable_register_club_member',
  );
  // check is if the registration is toggled to disabled
  const disableRegistration = !isRegisterClubMemberEnabled;

  useEffect(() => {
    if (amIMember.updated === 0) {
      getAmIMember();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: How do we test this properly?
  const saveMember = (memberInfo: CreateNewMemberFormValues) => {
    createMemberData({ body: memberInfo });
  };

  const onSuccessfulSave = () => {
    getAmIMember();
  };

  const handleNext = () => {
    setActiveStep(1);
    onSuccessfulSave();
  };

  const isAuthenticated =
    amIMember.data && (amIMember.data.isMember === true || amIMember.data.isMember === false);

  const isMember = !!(
    amIMember &&
    amIMember.updated !== 0 &&
    amIMember.data &&
    amIMember.data.isMember
  );

  const isLoading = amIMember.updated === 0 || amIMember.loading;

  return (
    <RegisterWizard
      userInfo={userInfo}
      submitting={submitting}
      success={success}
      error={error}
      isLoading={isLoading}
      isMember={isMember}
      isAuthenticated={isAuthenticated}
      onSaveMember={saveMember}
      onNext={handleNext}
      disableRegistration={disableRegistration}
      contentfulData={contentfulData}
      activeStep={activeStep}
    />
  );
};
