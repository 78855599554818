/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { AphAlert } from '@aph/components/common/aph-alert/aph-alert.component';
import type { ConsentFormValues } from '@aph/components/common/gdpr/gdpr-consent/handle-consents';
import { sendEvent } from '@aph/components/gtm/useGtm';
import { Button } from '@aph/ui/components/button/button';
import { Typography } from '@aph/ui/components/typography/typography';
import type { MappedMemberConsent } from '~/components/common';
import {
  FeatureToggleComponent,
  GdprConsent,
  Off,
  On,
  SubmitButtonWithStates,
  mapMemberConsentsToConsent,
} from '~/components/common';
import type { ConsentV3, MemberConsentV3 } from '~/services/generated/CustomerClient';

type RegisterConsentProps = {
  consents: ConsentV3[] | undefined;
  memberConsents: MemberConsentV3[] | undefined;
  submittingMemberConsents: boolean | null;
  successMemberConsents: boolean | null;
  fetchFailed: boolean | null;
  errorMemberConsents: boolean;
  errorConsents: boolean;
  loadingMemberConsents: boolean;
  loadingConsents: boolean;
  openMoreInfo: (consentType: string) => void;
  onSave: (selectedConsentOptions: ConsentFormValues) => void;
};

export const RegisterConsent: React.FC<RegisterConsentProps> = ({
  consents,
  memberConsents,
  submittingMemberConsents,
  successMemberConsents,
  openMoreInfo,
  errorMemberConsents,
  errorConsents,
  loadingMemberConsents,
  loadingConsents,
  onSave,
  fetchFailed,
}) => {
  const intl = useIntl();
  const router = useRouter();
  const formStateConsent = useForm<ConsentFormValues>();
  const [mappedConsents, setMappedConsents] = useState<MappedMemberConsent[]>([]);
  const failedToSaveMessage = { message: intl.messages['CONSENT.SAVE.ERROR.MESSAGE'].toString() };

  useEffect(() => {
    setMappedConsents(mapMemberConsentsToConsent(consents, memberConsents));
  }, [memberConsents, consents]);

  const onSubmitConsents: SubmitHandler<ConsentFormValues> = (selectedConsentOptions) => {
    if (memberConsents) {
      onSave(selectedConsentOptions);
    }
  };

  const isLoadingMemberConsents =
    (loadingMemberConsents || !memberConsents?.length) && !errorMemberConsents;
  const isLoadingConsents = (loadingConsents || !consents?.length) && !errorConsents;

  if (isLoadingMemberConsents || isLoadingConsents) {
    return (
      <div
        className="[&_.skeleton]:bg-action-subtle flex animate-pulse flex-col gap-2"
        data-testid="REGISTER.CONSENT.SKELETON"
      >
        <div className="skeleton mb-1 h-9 w-full" />
        <div className="skeleton h-3 w-full" />
        <div className="skeleton h-3 w-full" />
        <div className="skeleton h-3 w-full" />
        <div className="skeleton h-[300px] w-full" />
        <div className="mb-2 flex self-start sm:w-auto sm:self-end">
          <div className="skeleton h-6 w-[240px] rounded-[48px]" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="w-full">
        <Typography typography="headingLarge" asChild color="text-brand">
          <h2 className="mb-1">
            <FeatureToggleComponent featureName="aph_features_retail_media_consent">
              <On>
                <FormattedMessage id="CLUB_MEMBER.REGISTER.CONSENT.TITLE_WITH_RETAIL_MEDIA" />
              </On>
              <Off>
                <FormattedMessage id="CLUB_MEMBER.REGISTER.CONSENT.TITLE" />
              </Off>
            </FeatureToggleComponent>
          </h2>
        </Typography>
        <Typography className="mb-2">
          <FeatureToggleComponent featureName="aph_features_retail_media_consent">
            <On>
              <FormattedMessage id="CONSENT.WITH_RETAIL_MEDIA_INGRESS" />
            </On>
            <Off>
              <FormattedMessage id="CONSENT.INGRESS" />
            </Off>
          </FeatureToggleComponent>
        </Typography>
      </div>
      <div className="w-full sm:max-w-[521px]">
        <AphAlert data-pw="register-consent-alert" severity="info">
          <Typography>
            OBS! Inom klubb Hjärtat hanterar vi aldrig personuppgifter om receptbelagda läkemedel
          </Typography>
        </AphAlert>
      </div>

      {(errorConsents || fetchFailed) && (
        <>
          <AphAlert
            data-pw="register-consent-alert"
            severity="error"
            variant="filled"
            title={intl.messages['CLUB_MEMBER.REGISTER.CONSENT.ERROR'].toString()}
            data-testid="ERROR.ALERT"
            sx={{ backgroundColor: 'transparent !important', border: 'none' }}
          />
          <div className="mt-1 flex flex-col items-center md:flex-row md:justify-end">
            <Button
              data-testid="REGISTER.CONSENT.CONTINUE.BUTTON"
              onClick={() => {
                router.push('/klubb-hjartat/start/');
              }}
            >
              <span>
                <FormattedMessage id="CONSENT.BUTTON.FORWARD" />
              </span>
            </Button>
          </div>
        </>
      )}
      {memberConsents && memberConsents.length > 0 && consents && consents?.length > 0 && (
        <FormProvider {...formStateConsent}>
          <form onSubmit={formStateConsent.handleSubmit(onSubmitConsents)}>
            {mappedConsents.map(
              (consent) =>
                consent.code && (
                  <GdprConsent
                    onOpenMoreInfo={openMoreInfo}
                    key={consent.code}
                    consentType={consent.code}
                    options={consent.options}
                    isChecked={consent.consentOptionCode}
                  />
                ),
            )}
            <div className="flex flex-col items-center md:items-end">
              {/* Checks if the error object from from react-hook-form contains any error (requierd consents is not selected) */}
              {Object.keys(formStateConsent.formState.errors).length > 0 && (
                <Typography className="text-error mb-1" data-testid="CONSENT.ERROR.MESSAGE">
                  <FormattedMessage id="CONSENT.ERROR.MESSAGE" />
                </Typography>
              )}
              <SubmitButtonWithStates
                className="mb-3 items-start md:w-auto md:items-end"
                buttonText={intl.formatMessage({ id: 'CLUB_MEMBER.CONSENT.SAVE' })}
                successButtonText={intl.formatMessage({ id: 'CLUB_MEMBER.CONSENT.SAVED' })}
                submitting={submittingMemberConsents}
                success={successMemberConsents}
                error={!fetchFailed && errorMemberConsents ? failedToSaveMessage : null}
                afterSuccessAction={() => {
                  router.push('/klubb-hjartat/start/');
                  // event is send to GTM when a member succellfull saved consents
                  sendEvent({ event: 'bli-medlem-registrering', data: 'samtycke' });
                }}
                afterSuccessActionDelay={0}
              />
              {successMemberConsents === false && (
                <Button
                  key="1"
                  onClick={() => {
                    router.push('/klubb-hjartat/start/');
                  }}
                >
                  <FormattedMessage id="CONSENT.BUTTON.FORWARD" />
                </Button>
              )}
            </div>
          </form>
        </FormProvider>
      )}
    </>
  );
};
