import * as link from '~/styles/elements/a';
import * as headings from '~/styles/elements/headings';
import theme from '~/styles/theme';
import * as font from '../../../../styles/fonts';

export const classes = {
  checkboxWrapper: {
    marginTop: theme.spacing(2),
    '& label': {
      alignItems: 'start',
      // styling of checkbox
      '& span:nth-of-type(1)': {
        padding: theme.spacing(0, 0.5, 0, 0),
      },
    },
  },
  nameFieldsWrapper: {
    width: '100%',
    height: 'auto',
  },
  text: {
    // styling of the text after checkbox
    '& span:nth-of-type(2)': {
      color: theme.palette['color/text/default'],
      fontSize: 16,
      lineHeight: '20px',
      letterSpacing: '0.3px',
    },
    '& a': {
      ...link,
    },
  },
  heading: {
    ...headings.h1,
    margin: theme.spacing(0, 0, 1, 0),
    lineHeight: '34px',
    letterSpacing: '0.3px',
    textAlign: 'left',
    // overides fontWeight and fontSize from heading h1 style
    fontFamily: font.ICAHeading,
    fontWeight: 400,
  },
  textField: {
    minHeight: 74,
    width: '100%',
    [theme.breakpoints.mqs.sm]: {
      width: '49%',
    },
  },
  textFieldWide: {
    width: '100%',
  },
  buttonSave: {
    [theme.breakpoints.mqs.sm]: {
      alignItems: 'flex-end !important',
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
};
