import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { FormattedMessage, useIntl } from 'react-intl';
import { AphAlert } from '@aph/components/common/aph-alert/aph-alert.component';
import type { ErrorCode, ErrorIntlKey, ErrorMessage } from '@aph/utilities/errors/types';
import type { IPageClubHjartatFields } from '~/contentful/types';
import type { UserInfo } from '~/services/generated/DispatcherClient';
import { Notice404 } from '../../../error/notice-404/notice-404.component';
import type { CreateNewMemberFormValues } from '../../member-info.schema';
import { RegisterConsentContainer } from '../register-consent/register-consent.container';
import { RegisterMemberLogin } from '../register-member-login/register-member-login.component';
import { RegisterMemberComponent } from '../register-member/register-member.component';

type RegisterWizardProps = {
  submitting: boolean | null;
  success: boolean | null;
  error: ErrorIntlKey | (ErrorMessage & ErrorCode) | null;
  userInfo: UserInfo | null;
  isLoading: boolean;
  isMember: boolean;
  isAuthenticated: boolean | null | undefined;
  onSaveMember: (memberInfo: CreateNewMemberFormValues) => void;
  disableRegistration: boolean;
  contentfulData: IPageClubHjartatFields;
  activeStep: number;
  onNext: () => void;
};

export const RegisterWizard: React.FC<RegisterWizardProps> = ({
  submitting,
  success,
  error,
  userInfo,
  isLoading,
  isMember,
  isAuthenticated,
  onSaveMember,
  disableRegistration,
  contentfulData,
  activeStep,
  onNext,
}) => {
  const intl = useIntl();
  const steps = [
    intl.formatMessage({ id: 'CLUB_MEMBER.REGISTER.STEP.MEMBER' }),
    intl.formatMessage({ id: 'CLUB_MEMBER.REGISTER.STEP.CONSENTS' }),
  ];
  const router = useRouter();

  useEffect(() => {
    if (isMember && activeStep === 0) {
      router.push('/klubb-hjartat/mina-uppgifter/');
    }
  }, [activeStep, isMember, router]);

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <RegisterMemberComponent
            userInfo={userInfo}
            onSuccessfulSave={onNext}
            errorMember={error}
            successMember={success}
            submittingMember={submitting}
            onSave={onSaveMember}
            data-testid="REGISTER.MEMBER.COMPONENT"
          />
        );
      case 1:
        return <RegisterConsentContainer data-testid="REGISTER.CONSENT.COMPONENT" />;
      default:
        return <Notice404 />;
    }
  };

  if (isAuthenticated === false || isAuthenticated === null) {
    return (
      <div className="max-w-2xl">
        <RegisterMemberLogin
          contentfulData={contentfulData}
          data-testid="REGISTER.MEMBER.LOGIN.COMPONENT"
        />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="sm:max-w-2xl" data-testid="REGISTER.MEMBER.SKELETON">
        <div className="[&_.skeleton]:bg-action-subtle flex animate-pulse flex-col gap-2">
          <div className="skeleton mb-2 h-9 w-full" />
          <div className="skeleton h-9 w-full" />
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
            <div className="skeleton h-9" />
            <div className="skeleton h-9" />
            <div className="skeleton h-9" />
            <div className="skeleton h-9" />
            <div className="skeleton h-9" />
          </div>
          <div className="skeleton h-4 w-full" />
          <div className="skeleton h-3 w-3/4" />
          <div className="skeleton h-3 w-3/4" />
          <div className="skeleton h-4 w-full" />
          <div className="skeleton h-6 min-w-[240px] self-center rounded-[48px]" />
        </div>
      </div>
    );
  }

  // When we want to turn off the registration of member on the web
  if (disableRegistration) {
    return (
      <div className="sm:max-w-[521px]" data-testid="REGISTER.DISABLED.ALERT">
        <AphAlert
          data-pw="register-wizard-alert"
          title={<FormattedMessage id="CLUB_MEMBER.REGISTER.DISABLED.TITLE" />}
          severity="info"
          variant="standard"
          data-testid="ERROR.ALERT"
        >
          <FormattedMessage id="CLUB_MEMBER.REGISTER.DISABLED.TEXT" />
        </AphAlert>
      </div>
    );
  }

  return (
    <div className="sm:max-w-2xl">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {getStepContent(activeStep)}
    </div>
  );
};
