import React, { useEffect } from 'react';
import { ConsentInfo } from '@aph/components/common/gdpr/consent-info/consent-info.component';
import type { ConsentFormValues } from '@aph/components/common/gdpr/gdpr-consent/handle-consents';
import { AphDrawer, mapSelectedOptionsToMemberConsents } from '~/components/common';
import { useConsentsActions, useConsentsStore } from '~/model/gdpr-consent/use-consents-store';
import {
  useMemberConsentActions,
  useMemberConsentStore,
} from '~/model/gdpr-consent/use-member-consent-store';
import { PanelType } from '~/model/panels/panels.panel-type';
import { usePanel } from '~/model/panels/usePanel';
import { RegisterConsent } from './register-consent.component';

export const RegisterConsentContainer: React.FC = ({ ...props }) => {
  const { getConsents } = useConsentsActions();
  const { consents } = useConsentsStore();
  const { memberConsents } = useMemberConsentStore();
  const { getMemberConsents, saveMemberConsents } = useMemberConsentActions();
  const moreInfoConsentsPersonalOffers = usePanel(
    PanelType.MORE_INFO_GDPR_CONSENTS_PERSONAL_OFFERS,
  );
  const moreInfoConsentsBonus = usePanel(PanelType.MORE_INFO_GDPR_CONSENTS_BONUS);
  const moreInfoConsentsRetailMedia = usePanel(PanelType.MORE_INFO_GDPR_CONSENTS_RETAIL_MEDIA);

  const {
    data: dataMemberConsents,
    submitting: submittingMemberConsents,
    success: successMemberConsents,
    error: errorMemberConsents,
    loading: loadingMemberConsents,
    fetchFailed,
  } = memberConsents;
  const { data: dataConsents, error: errorConsents, loading: loadingConsents } = consents;

  useEffect(() => {
    if (memberConsents && memberConsents.updated === 0) {
      getMemberConsents();
    }
  }, [memberConsents, getMemberConsents]);

  useEffect(() => {
    if (consents && consents.updated === 0) {
      getConsents();
    }
  }, [consents, getConsents]);

  const save = (selectedConsentOptions: ConsentFormValues) => {
    saveMemberConsents(
      mapSelectedOptionsToMemberConsents(dataMemberConsents.consents, selectedConsentOptions),
    );
  };

  const openDrawer = (consentType: string) => {
    switch (consentType) {
      case 'Bonus':
        moreInfoConsentsBonus.setPanelActive();
        break;
      case 'PersonalOffer':
        moreInfoConsentsPersonalOffers.setPanelActive();
        break;
      case 'RetailMedia':
        moreInfoConsentsRetailMedia.setPanelActive();
        break;
      default:
        break;
    }
  };

  return (
    <div {...props}>
      <RegisterConsent
        consents={dataConsents.consents}
        memberConsents={dataMemberConsents.consents}
        submittingMemberConsents={submittingMemberConsents}
        successMemberConsents={successMemberConsents}
        errorMemberConsents={errorMemberConsents}
        errorConsents={errorConsents}
        loadingMemberConsents={loadingMemberConsents}
        loadingConsents={loadingConsents}
        openMoreInfo={openDrawer}
        onSave={save}
        fetchFailed={fetchFailed}
      />
      <AphDrawer
        isPanelActive={moreInfoConsentsPersonalOffers.isPanelActive}
        onCloseHandler={moreInfoConsentsPersonalOffers.setPanelInactive}
        variant="wide"
        mobileVariant="wide"
        mobileAnchor="right"
      >
        <ConsentInfo
          onClose={moreInfoConsentsPersonalOffers.setPanelInactive}
          consentType="PersonalOffers"
        />
      </AphDrawer>
      <AphDrawer
        isPanelActive={moreInfoConsentsBonus.isPanelActive}
        onCloseHandler={moreInfoConsentsBonus.setPanelInactive}
        variant="wide"
        mobileVariant="wide"
        mobileAnchor="right"
      >
        <ConsentInfo onClose={moreInfoConsentsBonus.setPanelInactive} consentType="Bonus" />
      </AphDrawer>
      <AphDrawer
        isPanelActive={moreInfoConsentsRetailMedia.isPanelActive}
        onCloseHandler={moreInfoConsentsRetailMedia.setPanelInactive}
        variant="wide"
        mobileVariant="wide"
        mobileAnchor="right"
      >
        <ConsentInfo
          onClose={moreInfoConsentsRetailMedia.setPanelInactive}
          consentType="RetailMedia"
        />
      </AphDrawer>
    </div>
  );
};
